/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Button} from '@material-ui/core';
import Form from "react-validation/build/form";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router-dom";
import { register } from "../../services/auth.service";
import { getAll } from "../../services/UniversalAPI";
import validation from "../../services/validation";
import { useDialog } from '../../services/dialog/ModalProvider';
import ConsentRegister from "../consent/ConsentRegister";
import { FormatBoldTwoTone } from "@material-ui/icons";
import moment from "moment";
import "moment/locale/th";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    // '& .MuiInputLabel-outlined': {
    //   zIndex: 1,
    //   transform: 'translate(15px, 4px) scale(1)',
    //   pointerEvents: 'none'
    // },
    // '& .MuiInputLabel-shrink': {
    //   transform: 'translate(15px, -18px) scale(0.75)',
    // },
    '& .Mui-error .MuiInputBase-input': { color: '#f44336' }
  },
  alertDanger: {
    color: '#ec0016',
    backgroundColor: 'none',
    borderColor: 'none',
    position: 'relative',
    padding: '0',
    marginBottom: '1rem',
    border: 'none',
    borderRadius: '.25rem'
  },
  alertSuccess: {
    color: 'green',
    backgroundColor: 'none',
    borderColor: 'none',
    position: 'relative',
    padding: '0',
    marginBottom: '1rem',
    border: 'none',
    borderRadius: '.25rem'
  },
  containerCard: {
    width: '100%',
    margin: 0,
    maxWidth: '360px!important',
    padding: '20px 20px'
  },
  registerCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    // position: 'fixed',
    width: '100%',
    height: '100%',
    padding: 0
  }

}));

const Register = (props) => {
  const redirect = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useRef();
  const { setDialog } = useDialog();
  const [showPosition, setShowPosition] = useState(false);
  const usertypes = [
    { userTypeId: 1, userTypeName: "เด็ก (Child)" },
    { userTypeId: 2, userTypeName: "ผู้ปกครอง/ผู้ดูแล (Parents)" },
    { userTypeId: 3, userTypeName: "เจ้าหน้าที่ (Officer)" }
  ]
  const [changwats, setChangwats] = useState([]);
  const [amphurs, setAmphurs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [{ disEmail, disPassword }, setDisabledState] = useState({ disEmail: false, disPassword: false });
  const [formData, setFormdata] = useState({
    userType: { userTypeId: 0, userTypeName: "" },
    fullname: '',
    position: '',
    cid: '',
    email: '',
    mobile: '',
    password: '',
    changwat: '',
    amphur: '',
    department: '',
    application: ["OSCC"],
    informConsent: {},
    // consent: {
    //   detail: { CR01: null, CR02: null, CR03: null, CR04: null, CR05: null, CR06: null, },
    //   result: '',
    //   language: ''
    // },
    registerDatetime: '',
    updateDatetime: '',
  });
  const [validator, setValidator] = useState({
    formElements: {
      userType: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      fullname: {
        validator: { required: true, minLength: 5 },
        error: { status: false, message: '' }
      },
      position: {
        validator: {},
        error: { status: false, message: '' }
      },
      cid: {
        validator: { required: true, number: true, stringLength: 13, pattern: 'mod13' },
        error: { status: false, message: '' }
      },
      email: {
        validator: { required: true, pattern: 'email' },
        error: { status: false, message: '' }
      },
      password: {
        validator: { required: true, minLength: 6 },
        error: { status: false, message: '' }
      },
      mobile: {
        validator: { required: true, stringLength: 10 },
        error: { status: false, message: '' }
      },
      changwat: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      amphur: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      department: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      consent: {
        validator: { required: false },
        error: { status: false, message: '' }
      }
    },
    formValid: true
  });
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    let unmounted = false;
    const setLineInfo = () => {
      if (typeof props.lineInfo !== 'undefined') {
        if (props.lineInfo.email !== '' && props.lineInfo.password !== '') {
          if (!unmounted) {
            setFormdata({
              ...formData,
              fullname: props.lineInfo.fullname,
              email: props.lineInfo.email,
              password: props.lineInfo.password,
              lineRegister: true
            });
            setDisabledState({ disEmail: true, disPassword: true });
          }
        }
      }
    }
    setLineInfo();
    return () => { unmounted = true };
    // eslint-disable-next-line 
  }, [props.lineInfo]);
  useEffect(() => {
    let unmounted = false;
    getChangwat();

    return () => { unmounted = true };
    // eslint-disable-next-line 
  }, []);
  useEffect(() => {
    if (formData.userType.userTypeId !== 0) {
      toggleElement(formData.userType.userTypeId);
      setOpenDialog(true);
    }
  }, [formData.userType.userTypeId]);
  // useEffect(() => {
  //   console.log(formData);
  // }, [formData.consent]);

  const onElementChange = (e) => {
    let result = validation(validator, e);
    if (typeof result !== 'undefined') {
      let name = (typeof e.target !== 'undefined') ? e.target.name : e.name;
      let value = (typeof e.target !== 'undefined') ? e.target.value : e.value;
      if (name === 'email') {
        value = value.toLowerCase();
      }
      setFormdata({ ...formData, [name]: value });
      setValidator({ ...validator, formElements: result.formElements, formValid: result.formValid });
    }
  }
  const getChangwat = async () => {
    let response = await getAll({ filter: { "fields": { "changwatcode": "true", "changwatname": "true" }, "where": { "zonecode": { "inq": ["08","01"]} } } }, 'cchangwats');
    setChangwats(response.data);
  }
  const getAmphur = async (cw) => {
    let response = await getAll({ filter: { "fields": { "amphur_id": "true", "amphur_name": "true" }, "where": { "province_id": cw } } }, 'amphurs');
    setAmphurs(response.data);
  }
  const getDepartment = async (am) => {
    let response = await getAll({ filter: { "fields": { "hos_id": "true", "hos_name": "true", "hos_fullname": "true" }, "where": { "hos_ampid": am } } }, 'hospitals');
    setDepartments(response.data);
  }
  const toggleElement = (userTypeId) => {
    if (userTypeId !== 3) {
      validator.formElements.position.validator = { required: false };
      validator.formElements.department.validator = { required: false };
      setFormdata({ ...formData, position: '' });
      setShowPosition(false);
    } else {
      validator.formElements.position.validator = { required: true, minLength: 5 };
      validator.formElements.department.validator = { required: true };
      setShowPosition(true);
    }
  }
  const onFormSubmit = (event) => {
    event.preventDefault();
    // Validate all element in form 
    // console.log(formData);
    if (validator.formValid) {
      Object.entries(formData).forEach(([k, v]) => {
        onElementChange({ name: k, value: v });
      })
    }
    let formStatus = true;
    let form = { ...validator.formElements };

    for (let name in form) {
      if (form[name].validator.required === true) {
        formStatus = (!form[name].error.status) ? formStatus : false;
      }
    }

    if (formStatus) {
      setValidator({ ...validator, formValid: false });
      // console.log(formData);
      formData.registerDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
      formData.updateDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
      // console.log(formData);
      register(formData).then((response) => {
        if (response.status === 200) {
          // console.log(props);
          let redirectPath = "/login";
          if (typeof props.location !== 'undefined') {
            redirectPath = (props.location.type === "byAdmin") ? "/userlist" : "/login";
          }
          setDialog({
            title: 'ผลการลงทะเบียน',
            titleStyle: { padding: '8px 16px', color: '#383838', borderBottom: '1px solid #ccc' },
            content: 'ลงทะเบียนสำเร็จ รอผู้ดูแลระบบอนุมัติการใช้งาน',
            contentStyle: { width: '100%', textAlign: 'center', color: 'green' },
            button: {
              confirm: { enable: false, text: '' },
              cancel: { enable: true, text: 'ปิด', redirect: redirectPath },
            }
          });
        } else if (response.status === 422) {
          setValidator({ ...validator, formValid: true });
          setDialog({
            title: 'ผลการลงทะเบียน',
            titleStyle: { padding: '8px 16px', color: '#383838', borderBottom: '1px solid #ccc' },
            content: 'อีเมลล์นี้ถูกใช้ลงทะเบียนไปแล้ว กรุณาใช้อีเมลล์อื่น',
            contentStyle: { width: '100%', textAlign: 'center', color: 'red' },
            button: {
              confirm: { enable: false, text: '' },
              cancel: { enable: true, text: 'ปิด' },
            }
          });
        }
      });
    }
  };
  const registerForm = () => {
    return < div >
      <div className="form-group">
        <Autocomplete
          id="userType"
          size="small"
          fullWidth
          options={usertypes}
          getOptionSelected={(option, value) => value.userTypeName === option.userTypeName}
          getOptionLabel={(option) => option.userTypeName || ''}
          onChange={(e, newValue) => {
            if (newValue != null) {
              onElementChange({ name: 'userType', value: newValue });
            } else {
              onElementChange({ name: 'userType', value: { userTypeId: 0, userTypeName: "" } });
            }
          }}
          renderInput={(params) =>
            <TextField {...params}
              id="usertype_texfield"
              name="userType"
              label={t("pages.register.type")}
              variant="outlined"
              onChange={(e) => onElementChange(e)}
              error={validator.formElements.userType.error.status}
              helperText={validator.formElements.userType.error.message}
            />
          }
        />
      </div>
      <div className="form-group">
        <TextField
          id="fullname"
          name="fullname"
          label={t("pages.register.name")}
          type="text"
          size="small"
          variant="outlined"
          onChange={(e) => onElementChange(e)}
          value={formData.fullname}
          error={validator.formElements.fullname.error.status}
          helperText={validator.formElements.fullname.error.message}
        />
      </div>
      {showPosition && (
        <div className="form-group">
          <TextField
            id="position"
            name="position"
            label={t("pages.register.position")}
            type="text"
            size="small"
            variant="outlined"
            onChange={(e) => onElementChange(e)}
            value={formData.position}
            error={validator.formElements.position.error.status}
            helperText={validator.formElements.position.error.message}
          />
        </div>
      )}
      <div className="form-group">
        <TextField
          id="cid"
          name="cid"
          label={t("pages.register.cid")}
          type="text"
          size="small"
          variant="outlined"
          onChange={(e) => onElementChange(e)}
          value={formData.cid}
          error={validator.formElements.cid.error.status}
          helperText={validator.formElements.cid.error.message}
        />
      </div>
      <div className="form-group">
        <TextField
          id="mobile"
          name="mobile"
          label={t("pages.register.phone")}
          type="text"
          size="small"
          variant="outlined"
          onChange={(e) => onElementChange(e)}
          value={formData.mobile}
          error={validator.formElements.mobile.error.status}
          helperText={validator.formElements.mobile.error.message}
        />
      </div>
      <div className="form-group">
        <TextField
          id="email"
          name="email"
          label={t("pages.register.email")}
          type="text"
          size="small"
          variant="outlined"
          autoComplete='new-password'
          onChange={(e) => onElementChange(e)}
          value={formData.email}
          error={validator.formElements.email.error.status}
          helperText={validator.formElements.email.error.message}
          disabled={disEmail}
        />
      </div>
      <div className="form-group">
        <TextField
          id="password"
          name="password"
          label={t("pages.register.password")}
          type="password"
          size="small"
          variant="outlined"
          autoComplete='new-password'
          onChange={(e) => onElementChange(e)}
          value={formData.value}
          error={validator.formElements.password.error.status}
          helperText={validator.formElements.password.error.message}
          disabled={disPassword}
        />
      </div>
      <div className="form-group">
        <Autocomplete
          id="changwat"
          size="small"
          fullWidth
          options={changwats}
          getOptionSelected={(option, value) => value.changwatname === option.changwatname}
          getOptionLabel={(option) => option.changwatname || ''}
          onChange={(e, newValue) => {
            let changwatcode = (newValue != null) ? newValue.changwatcode : '';
            // let changwatname = (newValue != null) ? newValue.changwatname : '';
            onElementChange({ name: 'changwat', value: newValue });
            if (changwatcode !== '') { getAmphur(changwatcode) }
          }}
          renderInput={(params) =>
            <TextField {...params}
              id="changwat_texfield"
              name="changwat"
              label={t("pages.register.changwat")}
              variant="outlined"
              onChange={(e) => onElementChange(e)}
              error={validator.formElements.changwat.error.status}
              helperText={validator.formElements.changwat.error.message}
            />
          }
        />
      </div>
      <div className="form-group">
        <Autocomplete
          id="amphur"
          size="small"
          fullWidth
          options={amphurs}
          getOptionSelected={(option, value) => value === option}
          getOptionLabel={(option) => option.amphur_name || ''}
          onChange={(_, newValue) => {
            let amphur_id = (newValue != null) ? newValue.amphur_id : '';
            // let amphur_name = (newValue != null) ? newValue.amphur_name : '';
            onElementChange({ name: 'amphur', value: newValue });
            if (amphur_id !== '') { getDepartment(amphur_id) }
          }}
          renderInput={(params) => <TextField {...params}
            label={t("pages.register.ampur")}
            variant="outlined"
            onChange={(e) => onElementChange(e)}
            error={validator.formElements.amphur.error.status}
            helperText={validator.formElements.amphur.error.message}
          />}
        />
      </div>
      {showPosition && (
        <div className="form-group">
          <Autocomplete
            id="department"
            size="small"
            fullWidth
            options={departments}
            getOptionSelected={(option, value) => value === option}
            getOptionLabel={(option) => option.hos_name || ''}
            // value={formData.department}
            onChange={(_, newValue) => {
              if (newValue != null) {
                delete Object.assign(newValue, { 'hcode': newValue['hos_id'] })['hos_id'];
              }
              setFormdata({ ...formData, department: (newValue != null) ? newValue : '' });
              onElementChange({ name: 'department', value: (newValue != null) ? newValue : '' });
            }}
            renderInput={(params) => <TextField {...params}
              label={t("pages.register.agency")}
              variant="outlined"
              onChange={(e) => onElementChange(e)}
              error={validator.formElements.department.error.status}
              helperText={validator.formElements.department.error.message}
            />}
          />
        </div>
      )}
      <div className="form-group">
        <Button variant='outlined' color='primary' fullWidth onClick={()=>redirect.push('/login')} style={{ marginLeft: 0 }}>กลับ</Button>
        {/* <button className="btn btn-primary btn-block" onClick={()=>redirect.push('/login')}>กลับ</button> */}
      </div>
      <div className="form-group">
        <button className="btn btn-primary btn-block" disabled={!validator.formValid}>{t("pages.register.btn_register")}</button>
      </div>
    </div>
  }

  return (
    <div className={`col-md-12 ${classes.registerCard}`}>

      <ConsentRegister formData={formData} setFormdata={setFormdata} dialogOpen={openDialog} setDialogOpen={setOpenDialog} userTypeId={formData.userType.userTypeId} editConsent={false} t={t} />

      <div className={"card " + classes.containerCard}>
        <label htmlFor="caption" style={{ textAlign: 'center', marginBottom: '20px' }}>
          <h3>{t("pages.register.header")}</h3>
        </label>
        <Form className={classes.root} onSubmit={onFormSubmit} ref={form} autoComplete="new-password">
          {registerForm()}
        </Form>
      </div>
    </div>
  );
};

export default Register;
