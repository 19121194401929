/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Form from "react-validation/build/form";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router-dom";
import { editUser } from "../../services/auth.service";
import { getAll,patch } from "../../services/UniversalAPI";
import validation from "../../services/validation";
import { useDialog } from '../../services/dialog/ModalProvider';
import moment from "moment";
import "moment/locale/th";
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputLabel-outlined': {
      zIndex: 1,
      transform: 'translate(15px, 4px) scale(1)',
      pointerEvents: 'none'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(15px, -18px) scale(0.75)',
    },
    '& .Mui-error .MuiInputBase-input': { color: '#f44336' }
  },
  alertDanger: {
    color: '#ec0016',
    backgroundColor: 'none',
    borderColor: 'none',
    position: 'relative',
    padding: '0',
    marginBottom: '1rem',
    border: 'none',
    borderRadius: '.25rem'
  },
  alertSuccess: {
    color: 'green',
    backgroundColor: 'none',
    borderColor: 'none',
    position: 'relative',
    padding: '0',
    marginBottom: '1rem',
    border: 'none',
    borderRadius: '.25rem'
  },
  containerCard: { maxWidth: '400px!important', padding: '40px 40px' }
}));

const Register = (props) => {
  const redirect = useHistory();
  const classes = useStyles();
  const form = useRef();
  const { setDialog } = useDialog();
  const [showPosition, setShowPosition] = useState(false);
  const [userId, setUserId] = useState("");
  // const [userType, setUserType] = useState({userTypeId:0,userTypeName:""});
  // const usertypes = [
  //   { userTypeId:0, userTypeName:""},
  //   { userTypeId:1, userTypeName:"ผู้รับบริการ"},
  //   { userTypeId:2, userTypeName:"ญาติ/ผู้ดูแล"},
  //   { userTypeId:3, userTypeName:"เจ้าหน้าที่"}
  // ]
  const usertypes = [
    { userTypeId: 1, userTypeName: "เด็ก (Child)" },
    { userTypeId: 2, userTypeName: "ผู้ปกครอง/ผู้ดูแล (Parents)" },
    { userTypeId: 3, userTypeName: "เจ้าหน้าที่ (Officer)" }
  ];
  const [changwats, setChangwats] = useState([]);
  const [amphurs, setAmphurs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selected,setSelected]=useState({
    changwat : null,
    ampur : null,
    department : null
  });

  const [disEmail, setDisabledState] = useState(false);

  const [formData, setFormdata] = useState({
    userType:null,
    fullname: '',
    position: '',
    cid: '',
    email: '',
    mobile: '',
    changwat: {changwatcode:"",changwatname:""},
    amphur: {amphur_id:"",amphur_name:""},
    department: {hcode:"",hos_fullname:"",hos_name:""},
    application: ["OSCC"],
    updateDatetime: '',
  });
  const [validator, setValidator] = useState({
    formElements: {
      userType: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      fullname: {
        validator: { required: true, minLength: 5 },
        error: { status: false, message: '' }
      },
      position: {
        validator: {},
        error: { status: false, message: '' }
      },
      cid: {
        validator: { required: true, number: true, stringLength: 13, pattern: 'mod13' },
        error: { status: false, message: '' }
      },
      email: {
        validator: { required: true, pattern: 'email' },
        error: { status: false, message: '' }
      },
      mobile: {
        validator: { required: true, stringLength: 10 },
        error: { status: false, message: '' }
      },
      changwat: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      amphur: {
        validator: { required: true },
        error: { status: false, message: '' }
      },
      department: {
        validator: { required: true },
        error: { status: false, message: '' }
      }
    },
    formValid: true
  });
  useEffect( () => {
    let unmounted = false;
    let userInfo = props.location.userInfo;
    const initState = async() => {
      setUserId(userInfo.id);
      await getChwAmpHos("cchangwats");
      await getChwAmpHos("amphurs",userInfo.changwat.changwatcode);
      await getChwAmpHos("hospitals",userInfo.amphur.amphur_id);
      if(userInfo.userType !== undefined){
        if(userInfo.userType.userTypeId===3){
          setShowPosition(true);
        }else{
          setShowPosition(false);
        } 
        setFormdata({
          ...formData,
          userType: userInfo.userType,
          fullname: userInfo.fullname,
          position: userInfo.position,
          cid: userInfo.cid,
          email: userInfo.email,
          mobile: userInfo.mobile,
          changwat: userInfo.changwat,
          amphur: userInfo.amphur,
          department: userInfo.department,
          updateDatetime: ''
        });
      }else{
        setFormdata({
          ...formData,
          fullname: userInfo.fullname,
          position: userInfo.position,
          cid: userInfo.cid,
          email: userInfo.email,
          mobile: userInfo.mobile,
          changwat: userInfo.changwat,
          amphur: userInfo.amphur,
          department: userInfo.department,
          updateDatetime: ''
        });
      }

      setSelected({...selected,
        changwat : userInfo.changwat,
        ampur : userInfo.amphur,
        department : userInfo.department
      });
    }
    if (typeof userInfo !== 'undefined') {
      if (!unmounted) {
        initState();
        if(typeof userInfo.lineRegister !== 'undefined' && userInfo.lineRegister){
          setDisabledState(true);
        }
      }
    }

    return () =>  unmounted = true ;
    // eslint-disable-next-line 
  }, [props.location.userInfo]);

  const onElementChange = (e) => {
    let result = validation(validator, e);
    if (typeof result !== 'undefined') {
      const name = (typeof e.target !== 'undefined') ? e.target.name : e.name;
      const value = (typeof e.target !== 'undefined') ? e.target.value : e.value;
      if(["changwat","amphur","department"].includes( name )){
        if(name==="changwat"){
          let newChwVal = (value!=="")?value:null;
          setSelected({ ...selected,
            changwat : newChwVal,
            ampur : null,
            department : null
          });
          setFormdata({ ...formData, 
            changwat : newChwVal,
            amphur : null,
            department : null
          });
        }else if(name==="amphur"){
          let newAmpVal = (value!=="")?value:null;
          setSelected({ ...selected,
            ampur : newAmpVal,
            department : null
          });
          setFormdata({ ...formData, 
            changwat : formData.changwat,
            amphur : newAmpVal,
            department : null
          });
        }else if(name==="department"){
          let newHosVal = (value!=="")?value:null;
          setSelected({ ...selected, department : newHosVal});
          setFormdata({ ...formData, department : newHosVal});
        }
      }else if(name==="userType"){
        if(value!=null){
          if(value.userTypeId!==3){
            validator.formElements.position.validator={};
            setFormdata({ ...formData, position: '',[name]: value });
            setShowPosition(false);
          }else{
            validator.formElements.position.validator={ required: true, minLength: 5 };
            setFormdata({ ...formData, position: '',[name]: value });
            setShowPosition(true);
          } 
        }
      }else{
        setFormdata({ ...formData, [name]: value });
      }
      setValidator({ ...validator, formElements: result.formElements, formValid: result.formValid });
    }
  }
  const getChwAmpHos = async (model, val) => {
    try {
      const params = { filter: {} };
      switch (model) {
        case "cchangwats":
          params.filter.fields = { changwatcode: true, changwatname: true };
          params.filter.where = { zonecode: { "inq": ["08","01"]} };
          break;
        case "amphurs":
          params.filter.fields = { amphur_id: true, amphur_name: true };
          params.filter.where = { province_id: val };
          break;
        case "hospitals":
          params.filter.fields = { hos_id: true, hos_name: true, hos_fullname: true };
          params.filter.where = { hos_ampid: val };
          break;
        default: ;
      }
      // console.log(JSON.stringify(params));
      const response = await getAll(params, model);
      if (response.status === 200) {
        switch (model) {
          case "cchangwats": 
            // let newCW = [{changwatcode:"",changwatname:""}].concat(response.data);
            setChangwats(response.data); 
            setAmphurs([]); 
            setDepartments([]);
            break;
          case "amphurs": 
            // let newAM = [{amphur_id:"",amphur_name:""}].concat(response.data);
            setAmphurs(response.data); 
            setDepartments([]);
            break;
          case "hospitals": 
            // let newHS = [{hos_id:"",hos_fullname:"",hos_name:""}].concat(response.data);
            setDepartments(response.data); 
            break;
          default: ;
        }
      }
    } catch (error) { console.log('error', error) }
  };
  const onFormSubmit = (event) => {
    event.preventDefault();
    // Validate all element in form 
    if (validator.formValid) {
      Object.entries(formData).forEach(([k, v]) => {
        onElementChange({ name: k, value: v });
      })
    }
    let formStatus = true;
    let form = { ...validator.formElements };
    for (let name in form) {
      if (form[name].validator.required === true) {
        formStatus = (!form[name].error.status) ? formStatus : false;
      }
    }
    if (formStatus) {
      setValidator({ ...validator, formValid: false });
      // console.log(userId,formData);
      formData.updateDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
      patch(userId, formData, "teamusers").then(
        (response) => {
          if (response.status === 200) {
            setDialog({
              title: 'ผลการแก้ไขข้อมูล',
              titleStyle:{padding:'8px 16px',color: '#383838', borderBottom:'1px solid #ccc'},
              content: 'แก้ไขข้อมูลสำเร็จ',
              contentStyle: { width: '400px', textAlign: 'center', color: 'green' },
              button: {
                confirm: { enable: false, text: '' },
                cancel: { enable: true, text: 'ปิด', redirect: '/userlist' },
              }
            });
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  };
  const registerForm = () => {
    return < div >
      <div className="form-group">
        <Autocomplete
          id="userType"
          size="small"
          fullWidth
          options={usertypes}
          value={formData.userType}
          getOptionSelected={(option, value) => value.userTypeName === option.userTypeName}
          getOptionLabel={(option) => option.userTypeName || ''}
          onChange={(_, newValue) => {
            onElementChange({ name: 'userType', value: newValue });
          }}
          renderInput={(params) =>
            <TextField {...params}
              id="usertype_texfield"
              name="userType"
              label="ประเภทผู้ใช้งาน"
              variant="outlined"
              onChange={(e) => onElementChange(e)}
              error={validator.formElements.userType.error.status}
              helperText={validator.formElements.userType.error.message}
            />
          }
        />
      </div>
      <div className="form-group">
        <TextField
          id="fullname"
          name="fullname"
          label="ชื่อสกุล"
          type="text"
          size="small"
          variant="outlined"
          onChange={(e) => onElementChange(e)}
          value={formData.fullname}
          error={validator.formElements.fullname.error.status}
          helperText={validator.formElements.fullname.error.message}
        />
      </div>
      { showPosition && (
        <div className="form-group">
          <TextField
            id="position"
            name="position"
            label="ตำแหน่ง"
            type="text"
            size="small"
            variant="outlined"
            onChange={(e) => onElementChange(e)}
            value={formData.position}
            error={validator.formElements.position.error.status}
            helperText={validator.formElements.position.error.message}
          />
        </div>
      )}
      <div className="form-group">
        <TextField
          id="cid"
          name="cid"
          label="หมายเลขประจำตัวประชาชน"
          type="text"
          size="small"
          variant="outlined"
          onChange={(e) => onElementChange(e)}
          value={formData.cid}
          error={validator.formElements.cid.error.status}
          helperText={validator.formElements.cid.error.message}
        />
      </div>
      <div className="form-group">
        <TextField
          id="mobile"
          name="mobile"
          label="หมายเลขโทรศัพท์"
          type="text"
          size="small"
          variant="outlined"
          onChange={(e) => onElementChange(e)}
          value={formData.mobile}
          error={validator.formElements.mobile.error.status}
          helperText={validator.formElements.mobile.error.message}
        />
      </div>
      <div className="form-group">
        <TextField
          id="email"
          name="email"
          label="อีเมลล์"
          type="text"
          size="small"
          variant="outlined"
          autoComplete='new-password'
          onChange={(e) => onElementChange(e)}
          value={formData.email}
          error={validator.formElements.email.error.status}
          helperText={validator.formElements.email.error.message}
          disabled={disEmail}
        />
      </div>
      <div className="form-group">
        <Autocomplete
          id="changwat"
          size="small"
          fullWidth
          options={changwats}
          value={selected.changwat}
          getOptionSelected={(option, value) => value.changwatname === option.changwatname}
          getOptionLabel={(option) => option.changwatname || ''}
          onChange={(e, newValue) => {
            let changwatcode = (newValue != null) ? newValue.changwatcode : '';
            onElementChange({ name: 'changwat', value: newValue });
            getChwAmpHos("amphurs", changwatcode);
          }}
          renderInput={(params) =>
            <TextField {...params}
              id="changwat_texfield"
              name="changwat"
              label="จังหวัด"
              variant="outlined"
              onChange={(e) => onElementChange(e)}
              error={validator.formElements.changwat.error.status}
              helperText={validator.formElements.changwat.error.message}
            />
          }
        />
      </div>
      <div className="form-group">
        <Autocomplete
          id="amphur"
          size="small"
          fullWidth
          options={amphurs}
          value={selected.ampur}
          getOptionSelected={(option, value) => {
            return value.amphur_name === option.amphur_name;
          }}
          getOptionLabel={(option) => option.amphur_name || null}
          onChange={(_, newValue) => {
            let amphur_id = (newValue != null) ? newValue.amphur_id : null;
            onElementChange({ name: 'amphur', value: newValue });
            getChwAmpHos("hospitals", amphur_id);
          }}
          renderInput={(params) => <TextField {...params}
            label="อำเภอ"
            variant="outlined"
            onChange={(e) => onElementChange(e)}
            error={validator.formElements.amphur.error.status}
            helperText={validator.formElements.amphur.error.message}
          />}
        />
      </div>
      { showPosition && (
        <div className="form-group">
          <Autocomplete
            id="department"
            size="small"
            fullWidth
            options={departments}
            value={selected.department}
            getOptionSelected={(option, value) => value.hos_fullname === option.hos_fullname}
            getOptionLabel={(option) => option.hos_name || ''}
            onChange={(_, newValue) => {
              if (newValue != null) {
                delete Object.assign(newValue, { 'hcode': newValue['hos_id'] })['hos_id'];
              }
              let newHos = (newValue != null) ? newValue : null;
              onElementChange({ name: 'department', value: newHos });
              setFormdata({ ...formData, department: newHos });
            }}
            renderInput={(params) => <TextField {...params}
              label="หน่วยงาน"
              variant="outlined"
              onChange={(e) => onElementChange(e)}
              error={validator.formElements.department.error.status}
              helperText={validator.formElements.department.error.message}
            />}
          />
        </div>
      )}
      <div className="form-group">
        <button className="btn btn-primary btn-block" disabled={!validator.formValid}>บันทึก</button>
      </div>
    </div>
  }

  return (
    <div className="col-md-12">
      <div className={"card " + classes.containerCard}>
        <label htmlFor="caption" style={{ textAlign: 'center', marginBottom: '20px' }}>
          <h3>แก้ไขผู้ใช้งาน</h3>
        </label>
        <Form className={classes.root} onSubmit={onFormSubmit} ref={form} autoComplete="new-password">
          {registerForm()}
        </Form>
      </div>
    </div>
  );
};

export default Register;
